// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";
.file-upload-input {
  display: none;
}

.file-upload-label {
  display: inline-block;
  padding: 8px 12px;
  background-color: #4CAF50;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.file-upload-label:hover {
  background-color: #3e8e41;
}


.fileeeDiw{
  background-color: #c8ced3;
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;

}
